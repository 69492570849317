import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Button } from "../Button";
import { ReactComponent as Question } from "../../assets/questionPay.svg";
import cvcInfo from "../../assets/cvcInfo.svg";
import {
  OneRowInput,
  CardNumberElementStyled,
  CardExpiryElementStyled,
  CardCvcElementStyled,
  InfoPay,
  Item,
  AboutCVC,
  FloatingQuestin,
} from "./style";

import { createSubscription } from "./utils/createSubscription";
import {
  useStripe,
  useElements,
  CardNumberElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";

import { EmailDescription } from "../Input/style";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { dic } from "../../App";
import amplitude from "amplitude-js";
import { confirmSubscription } from "./utils/confirmSubscription";

const inputStyles = {
  style: {
    base: {
      fontSize: "18px",
      lineHeight: "25px",

      "::placeholder": {
        fontSize: "18px",
        lineHeight: "25px",
        color: "rgba(0, 0, 0, 0.2)",
      },
    },
    invalid: {
      color: "#e8573e",
    },
  },
};

export const CheckoutForm = ({ tariff, price, user, nameOfTariff, isRtl }) => {
  const { t } = useTranslation("landing");

  const [hasCardNumber, setHasCardNumber] = useState(false);
  const [hasExpiry, setHasExpiry] = useState(false);
  const [hasCVV, setHasCVV] = useState(false);
  const [completeCardNumber, setCompleteCardNumber] = useState(false);
  const [completeExpiry, setCompleteExpiry] = useState(false);
  const [completeCVV, setCompleteCVV] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const language = dic[i18n.language.split("-")[0]] || "ru";

  useEffect(() => {
    if (stripe) {
      if (tariff === "marafon") {
        const paymentRequestData = {
          country: "US",
          currency: "usd",
          total: {
            label: nameOfTariff,
            amount: 3999,
          },
        };

        const pr = stripe.paymentRequest(paymentRequestData);

        pr.on("paymentmethod", function (ev) {
          stripe
            .confirmCardPayment(
              user.clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            )
            .then(async (confirmResult) => {
              if (confirmResult.error) {
                const eventProps = {
                  how: "applepay",
                  product: tariff,
                  error_description: confirmResult.error,
                };
                amplitude
                  .getInstance()
                  .logEvent("landing_purchase_fail", eventProps);
                ev.complete("fail");
              } else {
                ev.complete("success");
                if (confirmResult.paymentIntent.status === "requires_action") {
                  stripe
                    .confirmCardPayment(user.clientSecret)
                    .then(async (result) => {
                      if (result.error) {
                        const eventProps = {
                          how: "applepay",
                          product: tariff,
                          error_description: result.error,
                        };
                        amplitude
                          .getInstance()
                          .logEvent("landing_purchase_fail", eventProps);
                        history.push(`/oops/${language}-pay-m`);
                      } else {
                        const confirmed = await confirmSubscription(
                          user.subscriptionId,
                          "applepay"
                        );
                        if (confirmed?.startDate) {
                          amplitude
                            .getInstance()
                            .logEvent("landing_purchase_success", {
                              product: tariff,
                            });
                          history.push(`/order/${language}-m`, confirmed);
                        }
                      }
                    });
                } else {
                  const confirmed = await confirmSubscription(
                    user.subscriptionId,
                    "applepay"
                  );
                  if (confirmed?.startDate) {
                    amplitude
                      .getInstance()
                      .logEvent("landing_purchase_success", {
                        product: tariff,
                      });
                    history.push(`/order/${language}-m`, confirmed);
                  }
                }
              }
            });
        });

        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }
  }, [nameOfTariff, price, stripe, tariff, user.clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    setLoading(true);
    amplitude.getInstance().logEvent("landing_start_purchase", {
      cvv: completeCVV,
      expire: completeExpiry,
      number: completeCardNumber,
      how: "card",
      product: tariff,
    });
    const cardNumber = elements.getElement(CardNumberElement);
    if (tariff === "sprint") {
      try {
        const result = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumber,
        });
        if (result.error) {
          setPaymentLoading(false);
          setLoading(false);
          return result;
        }
        const sbscr = await createSubscription({
          customerId: user.id,
          paymentMethodId: result.paymentMethod?.id,
          priceId: tariff,
          locale: language,
          productId: tariff,
          how: "card",
        });
        if (!sbscr.error) {
          amplitude
            .getInstance()
            .logEvent("landing_purchase_success", { product: tariff });
          history.push(`/order/${language}-s`, {
            ...sbscr,
            isSubscription: false,
            priceId: tariff,
          });
        }
      } catch (error) {
        const eventProps = {
          how: "card",
          product: tariff,
          error_description: error,
        };
        amplitude.getInstance().logEvent("landing_purchase_fail", eventProps);
        console.error(error);
        history.push(`/oops/${language}-pay-s`);
      }
    }
    if (tariff === "marafon") {
      try {
        const data = await stripe.confirmCardPayment(user.clientSecret, {
          payment_method: {
            card: cardNumber,
          },
        });
        if (data.error?.code === "incorrect_number") {
          setPaymentLoading(false);
          setLoading(false);
          history.push(`/oops/${language}-pay-m`);
          return;
        }
        if (data?.paymentIntent?.status === "succeeded") {
          const confirmed = await confirmSubscription(user.subscriptionId);
          if (confirmed?.startDate) {
            amplitude
              .getInstance()
              .logEvent("landing_purchase_success", { product: tariff });
            history.push(`/order/${language}-m`, confirmed);
          }
          return;
        }
      } catch (error) {
        const eventProps = {
          how: "card",
          product: tariff,
          error_description: error,
        };
        amplitude.getInstance().logEvent("landing_purchase_fail", eventProps);
        console.error(error);
        history.push(`/oops/${language}-pay-m`);
      }
    }
    setPaymentLoading(false);
    setLoading(false);
  };

  if (!user) {
    return <div>loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <EmailDescription>{t("numberCard")}</EmailDescription>
      <CardNumberElementStyled
        onChange={(e) => {
          setHasCardNumber(e.empty);
          setCompleteCardNumber(e.complete);
        }}
        id="cardNumber"
        options={inputStyles}
      />

      <OneRowInput>
        <div style={{ width: "100%" }}>
          <EmailDescription>{t("expiredAt")}</EmailDescription>
          <CardExpiryElementStyled
            onChange={(e) => {
              setHasExpiry(e.empty);
              setCompleteExpiry(e.complete);
            }}
            id="cardExpiry"
            options={{ ...inputStyles }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <EmailDescription>{t("cvv")}</EmailDescription>
          <AboutCVC>
            <CardCvcElementStyled
              id="cardCvc"
              onChange={(e) => {
                setHasCVV(e.empty);
                setCompleteCVV(e.complete);
              }}
              options={{ ...inputStyles, placeholder: "CVV" }}
            />
            <FloatingQuestin
              isRtl={isRtl}
              data-offset="{'bottom': '65px'}"
              data-class="floatinDetails"
              data-tip={`<div>
              <div class="whatIsCvv">${t("whatIsCvv")}</div>
              <div class="cvvIs">${t("cvvIs")}</div>
              <img height="74px" src="${cvcInfo}"/>
            </div>`}
            >
              <ReactTooltip
                effect="solid"
                place={isRtl ? "right" : "left"}
                html={true}
                arrowColor="#fff"
                backgroundColor="#fff"
                textColor="#000"
              />
              <Question
                isRtl={isRtl}
                style={{ transform: isRtl ? "scaleX(-1)" : "scaleX(1)" }}
              />
            </FloatingQuestin>
          </AboutCVC>
        </div>
      </OneRowInput>
      {tariff === "sprint" && (
        <InfoPay isRtl={isRtl}>
          <Item isRtl={isRtl}>
            <Trans>{t("todayNotPay")}</Trans>
          </Item>
          <Item isRtl={isRtl}>{t("youHave5Days")}</Item>
          <Item isRtl={isRtl}>{t("forConfirm")}</Item>
        </InfoPay>
      )}

      <Button
        renderLike="button"
        type="submit"
        text={tariff === "sprint" ? t("confirm") : t("payCard", { price })}
        disabled={isPaymentLoading || isLoading}
        styles={{
          width: "100%",
          maxWidth: "initial",
          marginTop: 32,
          background: "#27B535",
          boxShadow: "0px 4px 0px #209B2C",
        }}
      />
      {paymentRequest && (
        <>
          <div style={{ margin: "12px 0", textAlign: "center" }}>
            {t("payMomentum")}
          </div>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: { paymentRequestButton: { height: "52px" } },
            }}
          />
        </>
      )}
    </form>
  );
};
