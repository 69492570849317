import { Trans, useTranslation } from "react-i18next";
import { Container } from "../../components/Container";
import { ReactComponent as ArrowWhite } from "./icons/arrowWhite.svg";
import { ErrorPayStyled, Title, Top } from "../ErrorPay/style";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Button } from "../../components/Button/Button";
import {
  SuccessPayStyled,
  ThanksForBuy,
  ForDownload,
  CopyMessage,
  IfDontAccess,
  JustClick,
  Footer,
  YellowLink,
  NoObligation,
} from "./style";

import { TermsAndPrivacy, TermsAndPrivacyText, CopyRight } from "../../style";

import {
  InfoAboutPay,
  InfoAboutPayTitle,
  InfoAboutPayBody,
  InfoAboutPayBodyItem,
  InfoAboutPayBodyItemName,
  InfoAboutPayBodyItemValue,
} from "./style";
import { useEffect } from "react";
import i18n from "../../i18n";
import { dic } from "../../App";
import { rtlDetect } from "../../libs/isRtl";
import { Amplitude } from "@amplitude/react-amplitude";

export const Subscription = ({ state }) => {
  const { t } = useTranslation("landing");
  const history = useHistory();
  const isRtl = rtlDetect();
  const location = useLocation();
  const language = dic[i18n.language.split("-")[0]] || "ru";

  useEffect(() => {
    document.title = t("titleInfoPay");
  }, [t]);

  const afAdset = localStorage.getItem("af_adset");
  const afAd = localStorage.getItem("af_ad");

  const params = afAdset ? `&af_adset=${afAdset}&ad_ad=${afAd}` : "";
  const pid = localStorage.getItem("pid") || "Blog";

  return (
    <>
      <Container
        styles={{
          padding: "18px 0 24px",
          background:
            "linear-gradient(197.63deg, #784CF5 15.21%, #4C7BF5 84.84%)",
        }}
      >
        <ErrorPayStyled>
          <Top onClick={() => history.push(`/home/${language}-pay`)}>
            <ArrowWhite
              style={{
                transform: isRtl ? "scale(-1)" : "scale(1)",
              }}
            />
            <Title isRtl={isRtl} style={{ color: "#fff" }}>
              {t("return")}
            </Title>
          </Top>
          <SuccessPayStyled>
            <Logo />
            <ThanksForBuy>{t("thanksForBuy")}</ThanksForBuy>
            <ForDownload>
              <Trans>{t("download")}</Trans>
            </ForDownload>
            <Amplitude>
              {({ logEvent }) => (
                <Button
                  target="_blank"
                  renderLike="a"
                  href={`https://engbright.onelink.me/qZfm?c=discount&is_retargeting=true&af_inactivity_window=7d&af_sub1=${
                    state?.promoCode || null
                  }&is_retargeting=true&af_inactivity_window=7d&pid=${pid}${params}`}
                  text={t("downloadButton")}
                  onClick={() => logEvent("landing_finish_download")}
                />
              )}
            </Amplitude>
            <CopyMessage>{t("copyMessage")}</CopyMessage>
            <InfoAboutPay>
              <InfoAboutPayTitle>{t("infoAboutBuy")}</InfoAboutPayTitle>
              <InfoAboutPayBody>
                <InfoAboutPayBodyItem>
                  <InfoAboutPayBodyItemName>
                    {t("tariffBuy")}
                  </InfoAboutPayBodyItemName>
                  <InfoAboutPayBodyItemValue>
                    «{t(`rate.1.name`)}»
                  </InfoAboutPayBodyItemValue>
                </InfoAboutPayBodyItem>
                <InfoAboutPayBodyItem>
                  <InfoAboutPayBodyItemName>
                    {t("days")}
                  </InfoAboutPayBodyItemName>
                  <InfoAboutPayBodyItemValue>
                    {t("forever")}
                  </InfoAboutPayBodyItemValue>
                </InfoAboutPayBodyItem>
                <InfoAboutPayBodyItem>
                  <InfoAboutPayBodyItemName>
                    {t("price")}
                  </InfoAboutPayBodyItemName>
                  <InfoAboutPayBodyItemValue>$39.99</InfoAboutPayBodyItemValue>
                </InfoAboutPayBodyItem>
                {state?.startDate && (
                  <InfoAboutPayBodyItem>
                    <InfoAboutPayBodyItemName>
                      {t("createdAt")}
                    </InfoAboutPayBodyItemName>
                    <InfoAboutPayBodyItemValue>
                      {state.startDate}
                    </InfoAboutPayBodyItemValue>
                  </InfoAboutPayBodyItem>
                )}
              </InfoAboutPayBody>
            </InfoAboutPay>
            <IfDontAccess>{t("accept")}</IfDontAccess>
            <Amplitude>
              {({ logEvent }) => (
                <JustClick>
                  <Trans>
                    {t("linkToDownload")}{" "}
                    <YellowLink
                      target="_blank"
                      onClick={() => logEvent("landing_deeplink_activation")}
                      href={`https://engbright.onelink.me/qZfm?c=discount&is_retargeting=true&af_inactivity_window=7d&af_sub1=${
                        state?.promoCode || null
                      }&is_retargeting=true&af_inactivity_window=7d&pid=${pid}${params}`}
                    >
                      {t("link")}
                    </YellowLink>
                  </Trans>
                </JustClick>
              )}
            </Amplitude>
          </SuccessPayStyled>
        </ErrorPayStyled>
      </Container>
      <Container
        styles={{
          padding: "20px 0",
          background: "#4659c4",
        }}
      >
        <Footer>
          <NoObligation>
            <Trans>
              {t("anyQuestionMarafon")}
              <YellowLink href={t("supportLink")}>
                support@engbright.com
              </YellowLink>
            </Trans>
          </NoObligation>
          <TermsAndPrivacy
            isRus={location.pathname.includes("ru")}
            isRtl={isRtl}
          >
            <Amplitude>
              {({ logEvent }) => (
                <TermsAndPrivacyText
                  onClick={() => {
                    logEvent("landing_tap_terms_of_use");
                  }}
                  to={`/terms-of-use/${language}`}
                >
                  {t("Terms of use")}
                </TermsAndPrivacyText>
              )}
            </Amplitude>
            <Amplitude>
              {({ logEvent }) => (
                <TermsAndPrivacyText
                  onClick={() => {
                    logEvent("landing_tap_privacy_policy");
                  }}
                  to={`/privacy-policy/${language}`}
                >
                  {t("Privacy policy")}
                </TermsAndPrivacyText>
              )}
            </Amplitude>
          </TermsAndPrivacy>
          <CopyRight>
            {t("copyright", { year: new Date().getFullYear() })}
          </CopyRight>
        </Footer>
      </Container>
    </>
  );
};
