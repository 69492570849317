import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Description = styled.div`
  text-align: center;
  color: #fff;
  margin-top: 16px;
  margin-bottom: 40px;
  line-height: 22px;
`;

export const DescriptionBold = styled.div`
  font-weight: 800;
`;

export const IphoneImg = styled.img`
  width: 200%;
  margin-top: 46px;
  height: 875px;
`;

export const YellowText = styled.span`
  width: auto;
  all: inherit;
  color: rgb(255, 182, 18);
  margin: 0;
  display: inline-block;
  vertical-align: baseline;
`;

export const FlyingMan = styled.img`
  position: absolute;
  height: 217px;
  ${({ isRtl }) => (isRtl ? "left: -80px" : "right: -80px")};
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};

  top: 0px;
`;

export const BringWasCreated = styled.div`
  color: #fff;
  line-height: 22px;
  white-space: pre-line;
`;

export const TimeOfTrainingContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;

export const TimeOfTraining = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 77px;
`;

export const TimeOfTrainingNumber = styled.div`
  font-size: 48px;
  font-family: "FiraSansBold";
  text-align: center;
`;

export const TimeOfTrainingSpend = styled.div`
  text-align: center;
`;

export const ArrowImg = styled.img`
  align-self: center;
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
`;

export const WomanWithStrollerImg = styled.img`
  margin-top: 50px;
  ${({ isRtl }) => (isRtl ? "margin-left: -100px" : "margin-right: -100px")};
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
`;

export const OurTraining = styled.div`
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  font-weight: 700;
  margin-top: 50px;
  white-space: pre-line;
`;

export const FoundationOfLanguage = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  margin-top: 32px;
`;

export const DoUKnow = styled.div`
  background: linear-gradient(197.63deg, #784cf5 15.21%, #4c7bf5 84.84%);
  border-radius: 24px;
  padding: 40px 32px;
  margin-top: 64px;
  margin-left: -8px;
  margin-right: -8px;
`;

export const DoUKnowTitle = styled.div`
  font-size: 32px;
  line-height: 120%;
  color: #ffffff;
  margin-bottom: 16px;
  font-family: "FiraSansBold";
`;

export const DoUKnowForSpeak = styled.div`
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 24px;
  white-space: pre-line;

  strong {
    font-size: 20px;
    line-height: 27px;
    color: #ffba21;
    margin-bottom: 24px;
    white-space: pre-line;
  }
`;

export const DoUKnowFirstWords = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 24px;
`;

export const BrainLazyDescription = styled.div`
  margin-top: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: #262626;
  white-space: pre-line;
`;

export const LazyBrainImg = styled.img`
  margin-top: -30px;
  margin-bottom: -20px;
  height: 257px;
`;

export const ListOfReason = styled.div``;

export const Reason = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const ReasonImg = styled.img`
  margin-top: 4px;
`;

export const ReasonText = styled.div`
  line-height: 22px;
  ${({ isRtl }) => (isRtl ? "margin-right: 16px" : "margin-left: 16px")};
`;

export const MagicInAction = styled.div`
  line-height: 22px;
  margin-top: 24px;
  color: #262626;
`;

export const NameOfMethod = styled.div`
  margin-top: 24px;
  line-height: 22px;
`;

export const PollImage = styled.img`
  margin-top: 40px;
  min-height: 300px;
  background-size: cover;
  transform: ${({ isRtl }) => (isRtl ? "translateX(50%)" : "translateX(-50%)")};
  ${({ isRtl }) => (isRtl ? "margin-right: 50%;" : "margin-left: 50%;")};

  width: 100vw;
  max-width: 150%;
`;

export const PolPimsler = styled.div`
  margin-top: 28px;
  line-height: 22px;
`;

export const Proof = styled.div`
  margin-top: 20px;
  line-height: 22px;
`;

export const ListWithImage = styled.div``;

export const ListWithImageItem = styled.div`
  display: flex;
  margin-bottom: 40px;
  align-items: center;

  :last-child {
    margin-bottom: 0;
  }
`;

export const ListWithImageItemImg = styled.img`
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  transform: scale(1.4);
`;

export const ListWithImageText = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: #fff;
  white-space: pre-line;
  ${({ isRtl }) => (isRtl ? "margin-right: 20px;" : "margin-left: 20px;")};
`;

export const ListWithImageTextHighSpeed = styled(ListWithImageText)`
  color: #262626;
`;

export const BrightVideo = styled.video`
  margin: 10px -24px 20px;
  height: 359px;
`;

export const ListWithImageDesc = styled.div`
  line-height: 22px;
  color: #fff;
  margin-top: 7px;
`;

export const ListWithImageAdvantagesTitle = styled(ListWithImageText)`
  margin-left: 0;
  line-height: 27px;
`;

export const ListWithImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ isRtl }) => (isRtl ? "margin-right: 24px" : "margin-left: 24px")};
`;

export const ListWithImageItemAdvantages = styled(ListWithImageItem)`
  align-items: end;
  margin-bottom: 56px;
`;

export const PeopleImg = styled.img`
  position: absolute;
  bottom: -190px;
  left: 50%;
  transform: translateX(-50%);
`;

export const HaveQuestion = styled.div`
  margin-top: 56px;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  white-space: pre-line;
`;

export const SocialButtons = styled.div`
  display: flex;
  margin: 24px -2px 0;
  justify-content: space-between;

  svg {
    flex-shrink: 0;
  }
`;

export const SocialButton = styled.a`
  background: #ffffff;
  border-radius: 53px;
  width: 150px;
  height: 54px;
  padding: 0 15px;
  box-sizing: border-box;
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
`;

export const SocialButtonText = styled.span`
  color: #f3a614;
`;

export const AppStoreContainer = styled.a`
  display: block;
  margin: 88px auto 0;
`;

export const TermsAndPrivacy = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ isRtl, isRus }) =>
    isRtl ? "row-reverse" : isRus ? "column" : "row"};

  ${({ isRus }) => {
    if (isRus) {
      return css`
        & > a:first-child {
          border: none;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;
        }
      `;
    }

    return "";
  }}
`;

export const TermsAndPrivacyText = styled(Link)`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  :first-child {
    padding-right: 16px;
    margin-right: 16px;
    border-right: 2px solid rgba(255, 255, 255, 0.4);
  }
`;

export const CopyRight = styled.div`
  color: #fff;
  margin-top: 24px;
  text-align: center;
`;

export const FAQImg = styled.img`
  position: absolute;
  bottom: 100%;
  ${({ isRtl }) => (isRtl ? "left: 0" : "right: 0")};
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
`;

export const WrapperFotFlyingMan = styled.div`
  position: relative;
  margin-top: -10px;
  padding-top: 10px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 375px;
`;

export const CornerWrapper = styled.div`
  position: relative;

  ::before {
    content: "";
    position: absolute;
    display: block;
    width: 110px;
    height: 110px;
    ${({ isRtl }) => (isRtl ? "right: 0" : "left: 0")};
    box-shadow: ${({ isRtl }) =>
      isRtl ? "50px -50px 0 0 #5574f5" : "0 0 -50px -50px #4b7cf5"};
    top: 10px;
    border-radius: 50%;
    z-index: 10;
    overflow: hidden;
  }
`;

export const WrapperForQuestion = styled.div`
  width: 375px;
  margin: 0 auto;
  position: relative;
`;

export const QuestionImgFloating = styled.img`
  position: absolute;
  transform: translateY(-50%)
    ${({ isRtl }) => (isRtl ? "scaleX(-1)" : " scaleX(1)")};
  ${({ isRtl }) => (isRtl ? "left: -15px" : "right: -15px")};
`;

export const FlyingWoman = styled.img`
  position: absolute;
  top: 455px;
  height: 310px;
  width: 168px;
  transform: ${({ isRtl }) => (isRtl ? "scaleX(-1)" : "scaleX(1)")};
  ${({ isRtl }) => (isRtl ? "left: -30px" : "right: -30px")};
`;
