import { useLocation } from "react-router-dom";
import { Sprint } from "./Sprint";
import { Subscription } from "./Subscription";

const SuccessPay = ({ marafon }) => {
  const location = useLocation();
  const state = location.state;

  if (marafon) {
    return <Subscription state={state} />;
  }

  return <Sprint state={state} />;
};

export default SuccessPay;
