export const confirmSubscription = async (subscriptionId, how = "card") => {
  let adSource = localStorage.getItem("pid");
  const afAdset = localStorage.getItem("af_adset");
  const afAd = localStorage.getItem("af_ad");
  if (!adSource) adSource = "Blog";

  const result = await fetch(
    `${process.env.REACT_APP_PUBLIC_URL}/app-portal/landing/stripe/finish`,
    {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        subscriptionId,
        how,
        pid: adSource,
        afAdset,
        afAd,
      }),
    }
  ).then((response) => {
    // eslint-disable-next-line no-undef
    fbq("track", "Purchase", {
      value: 40,
      currency: "USD",
    });
    // eslint-disable-next-line no-undef
    twq("track", "Purchase", {
      value: 40,
      currency: "USD",
    });
    return response.json();
  });
  return result;
};
